import clsx from 'clsx';
import { ParagraphAndImageRowParagraphProps } from 'interfaces/cms/content';
import { rem } from 'lib';
import Image from 'components/common/Image';
import MarkdownWithBullet from 'components/common/MarkdownWithBulletV2';
import BasicLinkComponent from 'components/common/Link';
import ButtonComponent from 'components/common/Button';
import styles from './SingleParagraph.module.scss';
import Markdown from 'components/common/MarkdownV2';

function SingleParagraph({
  contentPosition,
  boxBackground,
  title,
  subtitle,
  align,
  titleColor,
  subtitleColor,
  image,
  button,
  isImageMobileFullWidth,
  bulletColor,
}: ParagraphAndImageRowParagraphProps) {
  const alignConfig = {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right',
    justify: 'text-justify',
  };
  function renderPositionContent() {
    let contentPositionClassName = `${styles.next_to_the_image}`;
    if (contentPosition === 'under_the_image') {
      contentPositionClassName = `${styles.under_the_image}`;
    }
    if (contentPosition === 'before_the_image') {
      contentPositionClassName = `${styles.before_the_image}`;
    }
    return `single_paragraph ${contentPositionClassName}`;
  }

  function renderBoxStyle() {
    if (boxBackground) {
      return {
        background: '#fff',
        padding: rem(20),
        borderRadius: rem(20),
        boxShadow: `${rem(1)} ${rem(10)} ${rem(15)} ${rem(
          -3
        )} rgba(0, 0, 0, 0.16)`,
      };
    }
    return {};
  }

  const paragraphImage = image && (
    <Image
      {...image}
      className={`${styles.image} ${
        isImageMobileFullWidth ? styles.image_no_border : ''
      }`}
      src={image.url}
      alt={image.alt}
      width={534}
      height={300}
      sizes="100vw"
      quality={50}
    />
  );

  const alignStyleConfig = {
    left: 'ml-0',
    right: 'mr-0',
    center: 'my-0 mx-auto',
  };

  const renderMarkdownWithBulletAndButton = (
    <>
      <div className={clsx(styles.single_paragraph_content, 'mt-[0.625rem]')}>
        <MarkdownWithBullet
          bulletColor={bulletColor as string}
          subtitle={subtitle}
          align={align}
          subtitleColor={subtitleColor}
        />
      </div>
      {button && (button.buttonPageSlug || button.buttonUrl) && (
        <div
          className={clsx('pt-[1.25rem] my-0 mx-auto', styles.paragraphButton)}
        >
          <BasicLinkComponent
            href={
              !button.buttonPageSlug
                ? button.buttonUrl
                : button.buttonPageSlug.slug
            }
            rel={button.buttonLinkRel}
            isExternalLink={!button.buttonPageSlug}
            passHref
          >
            <ButtonComponent {...button} btnStyle={alignStyleConfig[align]} />
          </BasicLinkComponent>
        </div>
      )}
    </>
  );

  const renderMarkdownParagraphTitle = (
    <Markdown
      className={`text-[1.625rem] font-bold leading-[1.9375rem] ${alignConfig[align]}`}
      md={title}
      textColor={titleColor}
    />
  );

  return (
    <div className={clsx('w-[100%] last-of-type:p-0', styles.single_paragraph)}>
      <div
        className={clsx(
          `hidden lg:grid w-[100%] grid-cols-12 gap-[30px] ${alignConfig[align]}`,
          renderPositionContent()
        )}
        style={renderBoxStyle()}
      >
        {contentPosition !== 'under_the_image' ? (
          <>
            <div className="col-span-6">{paragraphImage}</div>

            <div className="col-span-6 flex flex-col justify-center">
              {renderMarkdownParagraphTitle}
              {renderMarkdownWithBulletAndButton}
            </div>
          </>
        ) : (
          <div className="col-span-6 col-start-4 col-end-10 flex flex-col items-center justify-center">
            <div className="flex flex-col justify-center w-[100%]">
              <div className="w-[100%] pb-[1.25rem]">{paragraphImage}</div>
              <div>
                {renderMarkdownParagraphTitle}
                {renderMarkdownWithBulletAndButton}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="block lg:hidden">
        <div className="flex flex-col justify-center">
          <div
            className={clsx(
              isImageMobileFullWidth ? '-mx-[0.9375rem]' : 'mx-0',
              'pb-[1.25rem]'
            )}
          >
            {paragraphImage}
          </div>
          <div>
            {renderMarkdownParagraphTitle}
            <div
              className={clsx('mt-[0.625rem]', styles.single_paragraph_content)}
            >
              <div className={clsx('heart-bullet', bulletColor)}>
                <MarkdownWithBullet
                  bulletColor={bulletColor as string}
                  subtitle={subtitle}
                  align={align}
                  subtitleColor={subtitleColor}
                  cssClass="[&_a]:text-primary [&_a]:font-[600]"
                />
              </div>
            </div>
            {button && (button.buttonPageSlug || button.buttonUrl) && (
              <>
                <div
                  className={clsx(
                    'pt-[1.25rem] my-0 mx-auto',
                    styles.paragraphButton
                  )}
                >
                  <BasicLinkComponent
                    href={
                      !button.buttonPageSlug
                        ? button.buttonUrl
                        : button.buttonPageSlug.slug
                    }
                    rel={button.buttonLinkRel}
                    isExternalLink={!button.buttonPageSlug}
                    passHref
                  >
                    <ButtonComponent
                      {...button}
                      btnStyle={alignStyleConfig[align]}
                    />
                  </BasicLinkComponent>
                </div>
                {button.disclaimer && (
                  <div
                    className={clsx(
                      'pt-[0.625rem] w-[100%] flex items-start justify-center',
                      'button_disclaimer'
                    )}
                  >
                    <Markdown
                      className="text-center text-textColor"
                      textColor={button.disclaimer.color || 'var(--textColor)'}
                      md={button.disclaimer.title || ''}
                      hasLockIcon={button.disclaimer.lockIcon}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleParagraph;
